<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>Modules</ion-title>
        <ion-buttons slot="end">
<!--         <ion-fab-button size="small" slot="start">
         <ion-icon :icon="add"></ion-icon>
</ion-fab-button> -->

                      <ion-icon slot="start" :ios="archiveOutline" :md="archiveSharp"></ion-icon>

       </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content><!-- :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Les modules</ion-title>
        </ion-toolbar>
      </ion-header>-->

        <ion-list v-if="laListe">
          <ion-item v-for="mod in laListe" :key="mod._id" @click="() => router.push('/mods/'+mod.typeobj)">
            <ion-label>{{mod.nom}}</ion-label>
          </ion-item>
        </ion-list>


<!--
      <ion-fab vertical="top" horizontal="end" slot="fixed">
        <ion-fab-button @click="">
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
      </ion-fab> -->

   </ion-content>
  </ion-page>
</template>
<script>

import {  archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp, add } from 'ionicons/icons';


import {ref, computed, watch, reactive, onMounted} from 'vue'

  import { useRoute, useRouter } from 'vue-router';
import shared from "@/shared/shared";



export default {
  name: "BaseMods",
  inject:{shared: "shared"},
  components:{
    archiveOutline, archiveSharp
  },
 props:{
  isOpen :{
    default:false,
  },
    initialValues :{
      default: {},
    }

  },
 setup(props, context){
//alert("changement")
   const router = useRouter();
   const route = useRoute();
   //alert(JSON.stringify(route))
     shared.lister('modele')

    const formInfo=reactive({
      titre:"mon titre",
      soustitre:"",
      date:""
    });
const inEditMode = computed(()=> props.initialValues? props.initialValues.id?true:false:false)

  const handleDidDismiss= (isCancelled) => {


    if (!props.isOpen) return;

 let payload = null;
 if (isCancelled){
   payload = {
     isCancelled: true,
     formInfo: null
   };

 } else if (inEditMode.value){
   payload = {
     isCancelled: false,
     formInfo: {...formInfo, id:props.initialValues? props.initialValues.id? props.initialValues.id:null:null}
   };

 } else {
//      context.emit("modal-closed", {isCancelled, forminfo: !isCancelled?  formdata.value:null});
   delete formInfo.id;
   payload = {
     isCancelled: false,
     formInfo: {...formInfo}
   };
 }


 context.emit("modal-closed", payload);
    Object.assign(formInfo, {
      titre:"mon titre000",
      soustitre:"",
      date:""
    })

};
 watch(
     ()=> props.initialValues,
     (newVal) => {
       //alert('changement')
       //alert(JSON.stringify(newVal))
       //alert(inEditMode.value)
       if (newVal && inEditMode.value) {
         Object.assign(formInfo, props.initialValues)
         //alert(JSON.stringify(formInfo))
       }
     }
 )
return {
 //computed
  debug:shared.debug,
 inEditMode,
 //function
     handleDidDismiss,
  formInfo,
archiveOutline, archiveSharp,
route,
router,
  laListe:shared.laListe.value,
 jscmodeles:shared.jscmodeles,
add,
//add-circle-outline,
}
}
}
</script>

<style scoped>

</style>
